<template>
  <div class="odn-form">
    <slot />
  </div>
</template>

<style lang="scss">
.odn-form {
  padding: 16px;
}
</style>
