<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.filters') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ODNModalFilters>
        <ion-list>
          <ion-list-header>
            <ion-label>{{ $t('labels.filterProjects') }}</ion-label>
            <ion-button @click="onProjectsModal">
              <ion-icon slot="icon-only" :icon="icons.search"></ion-icon>
            </ion-button>
            <ion-button
              v-if="newFilters.projects.length"
              @click="onRemoveAllProjects"
              color="danger"
            >
              <ion-icon slot="icon-only" :icon="icons.trashOutline"></ion-icon>
            </ion-button>
          </ion-list-header>
          <ion-item lines="none">
            <ion-chip
              v-for="project in newFilters.projects"
              :key="project.id"
              @click="onRemoveProject(project)"
            >
              <ion-label>{{ project.name }}</ion-label>
              <ion-icon :icon="icons.closeCircle"></ion-icon>
            </ion-chip>
            <ion-text v-if="!newFilters.projects.length" color="medium">
              {{ $t('filters.allProjects') }}
            </ion-text>
          </ion-item>
          <ion-list-header>
            <ion-label>{{ $t('labels.sortBy') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment
              scrollable
              mode="ios"
              v-model="newFilters.sort"
              style="width: 100%"
            >
              <ion-segment-button value="project.name">
                <ion-label>{{ $t('filters.project') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="procedure.name">
                <ion-label>{{ $t('filters.name') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="procedure.created">
                <ion-label>{{ $t('filters.created') }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-list-header>
            <ion-label>{{ $t('labels.sortDirection') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment mode="ios" v-model="newFilters.direction">
              <ion-segment-button value="asc" layout="icon-start">
                <ion-icon :icon="icons.arrowUpOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.ascending') }}
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="desc" layout="icon-start">
                <ion-icon :icon="icons.arrowDownOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.descending') }}
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
        </ion-list>
        <div class="odn-modal-filters-buttons">
          <ion-button size="block" color="light" @click="reset">
            <ion-icon :icon="icons.refreshOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t('buttons.reset') }}
            </ion-label>
          </ion-button>
          <ion-button size="block" @click="dismissWithData">
            <ion-icon
              :icon="icons.checkmarkCircleOutline"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ $t('buttons.validate') }}
            </ion-label>
          </ion-button>
        </div>
      </ODNModalFilters>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonList,
  IonItem,
  IonChip,
  IonText,
  modalController,
} from '@ionic/vue';
import {
  close,
  closeCircle,
  search,
  trashOutline,
  arrowUpOutline,
  arrowDownOutline,
  refreshOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';

import ODNModalFilters from '@m/odn-modal-filters.vue';
import ODNModalProjects from '@m/odn-modal-project-list.vue';

export default {
  name: 'ModalStoreSurveysFilters',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonListHeader,
    IonList,
    IonItem,
    IonChip,
    IonText,
    ODNModalFilters,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        close,
        search,
        trashOutline,
        closeCircle,
        arrowUpOutline,
        arrowDownOutline,
        refreshOutline,
        checkmarkCircleOutline,
      },
      newFilters: {
        projects: [].concat(this.filters.projects),
        sort: this.filters.sort,
        direction: this.filters.direction,
      },
    };
  },
  methods: {
    reset() {
      this.newFilters = {
        projects: [],
        sort: 'procedure.created',
        direction: 'desc',
      };
      this.dismissWithData();
    },
    dismiss() {
      modalController.dismiss();
    },
    dismissWithData() {
      modalController.dismiss(this.newFilters);
    },
    async onProjectsModal() {
      const modal = await modalController.create({
        component: ODNModalProjects,
        componentProps: {
          selected: this.newFilters.projects,
        },
      });
      await modal.present();

      // wait for a response when closing the modal
      const newProject = (await modal.onDidDismiss()).data;

      if (newProject) {
        this.newFilters.projects.push(newProject);
      }
    },
    onRemoveAllProjects() {
      this.newFilters.projects = [];
    },
    onRemoveProject(project) {
      this.newFilters.projects = this.newFilters.projects.filter(
        (r) => r.id !== project.id
      );
    },
  },
};
</script>
