export function jwtDecrypt(token) {
  if (!token) return;
  const base64Url = token.split('.')[1];
  if (!base64Url) {
    throw new Error('Invalid token');
  }
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  try {
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (e) {
    throw new Error('Error parsing token');
  }
}

export async function dataUrlToFile(dataUrl, fileName, opts) {
  opts = opts || { type: 'image/jpeg' };
  const res = await fetch(dataUrl);
  const blob = await res.blob();
  return new File([blob], fileName, opts);
}

export function UTF8ToBase64(str) {
  return window.btoa(unescape(encodeURIComponent(str)));
}

export function Base64ToUTF8(str) {
  return decodeURIComponent(escape(window.atob(str)));
}

export const convertBlobToBase64 = blob =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

export const merge = (target, source) => {
  for (const key of Object.keys(source)) {
    if (source[key] instanceof Object && key in target)
      Object.assign(source[key], merge(target[key], source[key]));
  }

  Object.assign(target || {}, source);
  return target;
};

export const getMemeType = ext => {
  switch (ext.toLowerCase()) {
    case 'jpg':
      return 'image/jpeg';
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'gif':
      return 'image/gif';
    default:
      return ext;
  }
};
