import APIService from '@s/api.service';

const state = () => ({
  notifications: [],
  notificationCount: 0,
});

const mutations = {
  SET_NOTIFICATIONS(state, payload) {
    state.notifications = payload;
  },
  SET_NOTIFICATION_COUNT(state, payload) {
    state.notificationCount = payload;
  },
};

const actions = {
  async fetchNotifications({ commit, rootState }) {
    try {
      const userId = rootState.auth.authData.userId;
      const response = await APIService.get(
        `/users/${userId}/notifications?unread=true`
      );
      commit('SET_NOTIFICATIONS', response.data.items);
      commit('SET_NOTIFICATION_COUNT', response.data.meta.totalItems);
    } catch (e) {
      commit('SET_NOTIFICATIONS', []);
      commit('SET_NOTIFICATION_COUNT', 0);
    }
  },
  async markNotificationAsRead({ rootState, dispatch }, notificationId) {
    try {
      const userId = rootState.auth.authData.userId;
      await APIService.put(
        `/users/${userId}/notifications/read/${notificationId}`
      );
      dispatch('fetchNotifications');
    } catch (e) {
      throw new Error('Error');
    }
  },
  async markAllNotificationsAsRead({ rootState, dispatch }) {
    try {
      const userId = rootState.auth.authData.userId;
      await APIService.put(`/users/${userId}/notifications/read`);
      dispatch('fetchNotifications');
    } catch (e) {
      throw new Error('Error');
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
