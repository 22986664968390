const state = () => ({
  selectedProject: null,
});

const mutations = {
  SELECT_PROJECT(state, payload) {
    state.selectedProject = payload;
  },
  DESELECT_PROJECT(state) {
    state.selectedProject = null;
  },
};

const actions = {
  selectProject({ commit }, payload) {
    commit('SELECT_PROJECT', payload);
  },
  deselectProject({ commit }) {
    commit('DESELECT_PROJECT');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
