import { Preferences } from '@capacitor/preferences';

const state = () => ({
  selectedTicket: null,
});

const mutations = {
  SELECT_TICKET(state, payload) {
    state.selectedTicket = payload;
  },
  DESELECT_TICKET(state) {
    state.selectedTicket = null;
  },
};

const actions = {
  async selectTicket({ commit }, payload) {
    commit('SELECT_TICKET', payload);

    await Preferences.set({
      key: 'selectedTicket',
      value: JSON.stringify(payload),
    });
  },
  async deselectTicket({ commit }) {
    commit('DESELECT_TICKET');

    await Preferences.remove({
      key: 'selectedTicket',
    });
  },
  async init({ commit }) {
    const selectedTicket = await Preferences.get({
      key: 'selectedTicket',
    });

    if (selectedTicket.value) {
      commit('SELECT_TICKET', JSON.parse(selectedTicket.value));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
