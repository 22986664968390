<template>
  <span class="odn-badge" :class="{ 'odn-badge-full': full }" :style="styles">
    <slot />
  </span>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: null,
    },
    full: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      return this.color ? { backgroundColor: this.color, color: 'white' } : {};
    },
  },
};
</script>

<style lang="scss">
.odn-badge {
  display: inline-flex;

  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;

  margin-bottom: 8px;
  padding: 2px 8px;

  font-size: 0.7rem;
  font-weight: 600;
  border-radius: 8px;

  &-full {
    display: flex;

    margin-left: unset;
    margin-right: unset;
    margin-inline: 10px;

    padding: 13px 16px;

    font-size: 0.9rem;
    font-weight: inherit;
    border-radius: 4px;
  }

  @media (prefers-color-scheme: dark) {
    color: white;
  }

  & > ion-icon {
    margin-right: 8px;
  }
}
</style>
