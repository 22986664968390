<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-title>
          {{ $t('modals.privacy') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <div class="odn-privacy">
        <div v-if="i18nTranslations === 'omni'" class="odn-privacy-content">
          <p>
            La société CHEIL traite vos données à caractère personnel pour
            l'organisation des opérations terrain gérées pour les clients de la
            société CHEIL. Vous disposez des droits suivants droit d'accès, de
            rectification, de mise à jour, l'effacement, droit de retirer à tout
            moment votre consentement, droit à la limitation du traitement,
            droit d'opposition, droit à la portabilité, droit de définir des
            directives quant au sort de vos données post mortem ainsi que le
            droit d'introduire une réclamation auprès de la CNIL.
          </p>
          <p>
            Pour en savoir plus sur la protection des données à caractère
            personnel, les traitements effectués par CHEIL et les modalités
            d'exercice de vos droits, vous pouvez consulter notre
            <a :href="privacyURL" target="_blank">
              Politique de confidentialité </a
            >.
          </p>
        </div>
        <div v-else class="odn-privacy-content">
          <p>
            La société INOVSHOP GROUP traite vos données à caractère personnel
            pour l'organisation des opérations terrain gérées pour les clients
            de la société INOVSHOP GROUP. Vous disposez des droits suivants
            droit d'accès, de rectification, de mise à jour, l'effacement, droit
            de retirer à tout moment votre consentement, droit à la limitation
            du traitement, droit d'opposition, droit à la portabilité, droit de
            définir des directives quant au sort de vos données post mortem
            ainsi que le droit d'introduire une réclamation auprès de la CNIL.
          </p>
          <p>
            Pour en savoir plus sur la protection des données à caractère
            personnel, les traitements effectués par INOVSHOP GROUP et les
            modalités d'exercice de vos droits, vous pouvez consulter notre
            <a :href="privacyURL" target="_blank">
              Politique de confidentialité </a
            >.
          </p>
        </div>

        <div class="odn-privacy-footer">
          <ion-item lines="none">
            <ion-checkbox slot="start" v-model="accepted"> </ion-checkbox>
            <ion-label class="ion-text-wrap">
              J'ai pris connaissance de la politique de confidentialité
            </ion-label>
          </ion-item>

          <div class="odn-mat-8">
            <ion-button
              type="submit"
              color="primary"
              expand="block"
              :disabled="!accepted"
              @click="understood"
            >
              {{ $t('buttons.ok') }}
              <ion-icon slot="start" :icon="checkmark"></ion-icon>
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonIcon,
  IonItem,
  IonCheckbox,
  IonLabel,
  modalController,
} from '@ionic/vue';
import { close, checkmark } from 'ionicons/icons';

export default {
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonIcon,
    IonItem,
    IonCheckbox,
    IonLabel,
  },
  data() {
    return {
      accepted: false,
      close,
      checkmark,
    };
  },
  computed: {
    privacyURL() {
      return `${process.env.VUE_APP_BACKEND_URL}/privacy`;
    },
    i18nTranslations() {
      return process.env.VUE_APP_I18N_TRANSLATIONS;
    },
  },
  methods: {
    dismiss() {
      modalController.dismiss();
    },
    understood() {
      modalController.dismiss(true);
    },
  },
};
</script>

<style lang="scss">
.odn-privacy {
  min-height: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &-content {
    padding: 16px 32px;
  }

  &-footer {
    padding: 32px;
  }
}
</style>
