<template>
  <div class="odn-modal-filters">
    <slot />
  </div>
</template>

<style lang="scss">
.odn-modal-filters {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  background-color: var(
    --ion-item-background,
    var(--ion-background-color, #fff)
  );

  &-buttons {
    padding: 16px;

    > * {
      margin-top: 16px;
    }
  }
}
</style>
