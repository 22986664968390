<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            :text="$t('buttons.back')"
            default-href="/tabs/stores"
          ></ion-back-button>
        </ion-buttons>
        <ion-title>
          {{ $t('views.storeDetails.title') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content v-if="store">
      <ion-card>
        <ion-card-header>
          <ion-row class="ion-justify-content-between ion-align-items-center">
            <ion-col size="auto">
              <ion-thumbnail v-if="store.retailer.logo">
                <img :src="store.retailer.logo" />
              </ion-thumbnail>
              <ion-thumbnail v-else>
                <img :src="`${s3Url}/medias/images/icons/icon-logo.png`" />
              </ion-thumbnail>
            </ion-col>
            <ion-col>
              <ion-card-subtitle>
                {{ store.retailer.name }} - {{ store.storeType.name }}
              </ion-card-subtitle>
              <ion-card-title>{{ store.name }}</ion-card-title>
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ion-row>
            <ion-col>
              <p>{{ store.address }}</p>
              <p>{{ store.postcode }} {{ store.city }}</p>
              <p>{{ store.country }}</p>
              <br v-if="store.region || store.territory || store.zone" />
              <p v-if="store.region">
                {{ $t('labels.region') }}
                {{ store.region.name }}
              </p>
              <p v-if="store.territory">
                {{ $t('labels.territory') }}
                {{ store.territory.name }}
              </p>
              <p v-if="store.zone">
                {{ $t('labels.zone') }}
                {{ store.zone.name }}
              </p>
            </ion-col>
            <ion-col class="ion-text-end">
              <p v-if="store.grade">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade') }} {{ store.grade }}
              </p>
              <p v-if="store.grade2">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade2') }} {{ store.grade2 }}
              </p>
              <p v-if="store.grade3">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade3') }} {{ store.grade3 }}
              </p>
              <p v-if="store.rank">
                <ion-icon src="assets/icons/icon-stars.svg"></ion-icon>
                {{ $t('labels.rank') }} {{ store.rank.name }}
              </p>
              <p v-if="store.code">
                {{ store.retailer.storeCode || this.$t('labels.code') }}
                {{ store.code }}
              </p>
              <p v-if="store.code2">
                {{ this.$t('labels.code2') }}
                {{ store.code2 }}
              </p>
              <p v-if="store.code3">
                {{ this.$t('labels.code3') }}
                {{ store.code3 }}
              </p>
            </ion-col>
          </ion-row>
          <div>
            <p v-if="store.email">
              <ion-icon :icon="icons.mailOutline" class="ion-margin-end" />
              <a :href="`mailto:${store.email}`">
                {{ store.email }}
              </a>
            </p>
            <p v-if="store.phone">
              <ion-icon :icon="icons.callOutline" class="ion-margin-end" />
              <a :href="`call:${store.phone}`">
                {{ store.phone }}
              </a>
            </p>
          </div>
        </ion-card-content>
      </ion-card>

      <ion-list lines="none">
        <!-- Surveys -->
        <ion-item button :router-link="`/tabs/stores/${store.id}/surveys`">
          <ion-icon :icon="icons.fileTrayFullOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.surveys') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.surveyCount > 0 ? 'primary' : null"
          >
            {{ store.surveyCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Tickets -->
        <ion-item
          v-if="profile.role && profile.role.name !== 'deployment'"
          button
          :router-link="`/tabs/stores/${store.id}/tickets`"
        >
          <ion-icon src="assets/icons/icon-tools.svg" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.tickets') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.ticketCount > 0 ? 'primary' : null"
          >
            {{ store.ticketCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Appointments -->
        <ion-item button :router-link="`/tabs/stores/${store.id}/appointments`">
          <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.appointments') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.appointmentCount > 0 ? 'primary' : null"
          >
            {{ store.appointmentCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Files -->
        <ion-item button :router-link="`/tabs/stores/${store.id}/files`">
          <ion-icon :icon="icons.documentOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.files') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.storeFileCount > 0 ? 'primary' : null"
          >
            {{ store.storeFileCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Furnitures -->
        <ion-item button :router-link="`/tabs/stores/${store.id}/furnitures`">
          <ion-icon :icon="icons.prismOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.furnitures') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.furnitures.length > 0 ? 'primary' : null"
          >
            {{ store.furnitures.length }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Contacts -->
        <ion-item button :router-link="`/tabs/stores/${store.id}/contacts`">
          <ion-icon :icon="icons.peopleOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.contacts') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.contacts.length > 0 ? 'primary' : null"
          >
            {{ store.contacts.length }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- Wlans -->
        <ion-item button :router-link="`/tabs/stores/${store.id}/wlans`">
          <ion-icon :icon="icons.wifiOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.wlans') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="store.wlans.length > 0 ? 'primary' : null"
          >
            {{ store.wlans.length }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
      </ion-list>

      <ion-card v-if="store.notes">
        <ion-card-header>
          <ion-card-title>
            {{ $t('sections.notes') }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- eslint-disable vue/no-v-html -->
          <div v-html="store.notes"></div>
        </ion-card-content>
      </ion-card>

      <ion-card button @click="onAddEditStoreComment">
        <ion-card-header>
          <ion-card-title>
            {{ $t('sections.comment') }}
          </ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="store.comment"
            v-html="store.comment"
            style="white-space: pre"
          ></div>
          <ion-text v-else color="primary">
            {{ $t('labels.clickHereToAddComment') }}
          </ion-text>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonList,
  IonItem,
  IonLabel,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRow,
  IonCol,
  IonThumbnail,
  IonSpinner,
  IonIcon,
  IonNote,
  IonText,
  toastController,
  modalController,
} from '@ionic/vue';
import {
  mailOutline,
  callOutline,
  fileTrayFullOutline,
  calendarOutline,
  documentOutline,
  prismOutline,
  peopleOutline,
  wifiOutline,
  star,
} from 'ionicons/icons';

import ODNModalStoreCommentForm from '@m/odn-modal-store-comment-form.vue';

import APIService from '@s/api.service';

export default {
  name: 'StoreDetails',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonList,
    IonItem,
    IonLabel,
    IonButtons,
    IonBackButton,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRow,
    IonCol,
    IonThumbnail,
    IonSpinner,
    IonIcon,
    IonNote,
    IonText,
  },
  data() {
    return {
      loading: true,
      icons: {
        mailOutline,
        callOutline,
        fileTrayFullOutline,
        calendarOutline,
        documentOutline,
        prismOutline,
        peopleOutline,
        wifiOutline,
        star,
      },
    };
  },
  computed: {
    ...mapState('auth', ['profile']),
    ...mapState('stores', {
      store: 'selectedStore',
    }),
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  async ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('stores', ['selectStore']),
    async fetchData() {
      try {
        const response = await APIService.get(
          `/stores/${this.$route.params.id}`
        );
        this.selectStore(response.data);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.store.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onAddEditStoreComment() {
      const modal = await modalController.create({
        component: ODNModalStoreCommentForm,
        componentProps: {
          storeId: parseInt(this.$route.params.id),
        },
      });
      await modal.present();

      const commentEdited = (await modal.onDidDismiss()).data;

      if (commentEdited) {
        this.fetchData();
      }
    },
  },
};
</script>
