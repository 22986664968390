import { createStore } from 'vuex';
import AuthModule from './modules/auth';
import PrefsModule from './modules/prefs';
import StoresModule from './modules/stores';
import ProjectsModule from './modules/projects';
import SurveysModule from './modules/surveys';
import ResponsesModule from './modules/responses';
import TicketsModule from './modules/tickets';
import AppointmentsModule from './modules/appointments';
import ProceduresModule from './modules/procedures';
import NotificationsModule from './modules/notifications';
import OfflineModule from './modules/offline';

const store = createStore({
  modules: {
    auth: AuthModule,
    prefs: PrefsModule,
    stores: StoresModule,
    projects: ProjectsModule,
    surveys: SurveysModule,
    responses: ResponsesModule,
    tickets: TicketsModule,
    appointments: AppointmentsModule,
    procedures: ProceduresModule,
    notifications: NotificationsModule,
    offline: OfflineModule,
  },
});

export default store;
