import { Preferences } from '@capacitor/preferences';

const state = () => ({
  selectedStore: null,
});

const mutations = {
  SELECT_STORE(state, payload) {
    state.selectedStore = payload;
  },
  DESELECT_STORE(state) {
    state.selectedStore = null;
  },
};

const actions = {
  async selectStore({ commit }, payload) {
    commit('SELECT_STORE', payload);

    await Preferences.set({
      key: 'selectedStore',
      value: JSON.stringify(payload),
    });
  },
  async deselectStore({ commit }) {
    commit('DESELECT_STORE');

    await Preferences.remove({
      key: 'selectedStore',
    });
  },
  async init({ commit }) {
    const selectedStore = await Preferences.get({
      key: 'selectedStore',
    });

    if (selectedStore.value) {
      commit('SELECT_STORE', JSON.parse(selectedStore.value));
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
