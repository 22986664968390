<template>
  <div class="odn-badges">
    <slot />
  </div>
</template>

<style lang="scss">
.odn-badges {
  &:not(:last-child) {
    margin-bottom: 8px;
  }

  > .odn-badge:not(:last-child) {
    margin-right: 8px;
  }
}
</style>
