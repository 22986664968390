const state = () => ({
  selectedAppointment: null,
});

const mutations = {
  SELECT_APPOINTMENT(state, payload) {
    state.selectedAppointment = payload;
  },
  DESELECT_APPOINTMENT(state) {
    state.selectedAppointment = null;
  },
};

const actions = {
  selectAppointment({ commit }, payload) {
    commit('SELECT_APPOINTMENT', payload);
  },
  deselectAppointment({ commit }) {
    commit('DESELECT_APPOINTMENT');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
