<template>
  <ion-spinner class="odn-spinner"></ion-spinner>
</template>

<script>
import { IonSpinner } from '@ionic/vue';

export default {
  components: {
    IonSpinner,
  },
};
</script>

<style lang="scss">
.odn-spinner {
  width: 14px;
  margin-left: 8px;
}
</style>
