import axios from 'axios';
import { jwtDecrypt } from '../../shared/helper';
import { Preferences } from '@capacitor/preferences';

const state = () => ({
  authData: {
    token: '',
    tokenExp: '',
    userId: '',
    userEmail: '',
  },
  loggedIn: false,
  profile: {
    role: null,
    assignment: null,
    groups: [],
  },
});

const getters = {
  getLoginStatus(state) {
    return state.loggedIn;
  },
  getAuthData(state) {
    return state.authData;
  },
};

const mutations = {
  saveAuthToken(state, payload) {
    const jwtDecodeUserInfo = jwtDecrypt(payload.accessToken);
    const newAuthData = {
      token: payload.accessToken,
      tokenExp: jwtDecodeUserInfo.exp,
      userId: jwtDecodeUserInfo.sub,
      userEmail: jwtDecodeUserInfo.email,
    };
    state.authData = newAuthData;
  },
  clearAuthToken(state) {
    state.authData = {
      token: '',
      tokenExp: '',
      userId: '',
      userEmail: '',
    };
    state.loggedIn = false;
  },
  saveLoginStatus(state, status) {
    state.loggedIn = status;
  },
  setProfile(state, payload) {
    const { role, assignment, groups } = payload;

    state.profile.role = { id: role.id, name: role.name };
    state.profile.assignment = assignment
      ? { id: assignment.id, name: assignment.name }
      : null;
    state.profile.groups = groups.map((g) => g.id);
  },
  clearProfile(state) {
    state.profile.role = null;
    state.profile.assignment = null;
    state.profile.groups = [];
  },
};

const actions = {
  async login({ commit, dispatch }, payload) {
    try {
      const response = (
        await axios.post(`${process.env.VUE_APP_API_URL}/auth/login`, payload)
      ).data;

      await Preferences.set({
        key: 'accessToken',
        value: response.accessToken,
      });

      commit('saveAuthToken', response);
      commit('saveLoginStatus', true);

      try {
        const profile = (
          await axios.get(`${process.env.VUE_APP_API_URL}/auth/profile`, {
            headers: {
              Authorization: 'Bearer ' + response.accessToken,
            },
          })
        ).data.user;

        dispatch('saveProfile', profile);
      } catch (e) {
        dispatch('removeProfile');
      }
    } catch (e) {
      commit('saveLoginStatus', false);
    }
  },
  async logout({ commit, dispatch }) {
    await Preferences.remove({ key: 'accessToken' });
    await Preferences.remove({ key: 'profile' });

    commit('clearAuthToken');
    commit('clearProfile');

    dispatch('offline/clearState', null, { root: true });
    dispatch('offline/deleteAllOfflineSavedItems', null, { root: true });
  },
  async loadPreferencesTokens({ commit }) {
    const accessToken = await Preferences.get({ key: 'accessToken' });

    if (accessToken.value) {
      const tokenData = {
        accessToken: accessToken.value,
      };
      commit('saveAuthToken', tokenData);
    }
  },
  async loadProfile({ commit }) {
    const profile = await Preferences.get({
      key: 'profile',
    });

    if (profile.value) {
      commit('setProfile', JSON.parse(profile.value));
    }
  },
  async saveProfile({ state, commit }, payload) {
    commit('setProfile', payload);

    await Preferences.set({
      key: 'profile',
      value: JSON.stringify(state.profile),
    });
  },
  async removeProfile({ commit }) {
    commit('clearProfile');
    await Preferences.remove({ key: 'profile' });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
