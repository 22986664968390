<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ $t('modals.filters') }}
        </ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ODNModalFilters>
        <ion-list>
          <ion-list-header>
            <ion-label>{{ $t('labels.filterRetailers') }}</ion-label>
            <ion-button @click="onRetailersModal">
              <ion-icon slot="icon-only" :icon="icons.search"></ion-icon>
            </ion-button>
            <ion-button
              v-if="newFilters.retailers.length"
              @click="onRemoveAllRetailers"
              color="danger"
            >
              <ion-icon slot="icon-only" :icon="icons.trashOutline"></ion-icon>
            </ion-button>
          </ion-list-header>
          <ion-item lines="none">
            <ion-chip
              v-for="retailer in newFilters.retailers"
              :key="retailer.id"
              @click="onRemoveRetailer(retailer)"
            >
              <ion-label>{{ retailer.name }}</ion-label>
              <ion-icon :icon="icons.closeCircle"></ion-icon>
            </ion-chip>
            <ion-text v-if="!newFilters.retailers.length" color="medium">
              {{ $t('filters.allRetailers') }}
            </ion-text>
          </ion-item>
          <ion-list-header>
            <ion-label>{{ $t('labels.sortBy') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment
              scrollable
              mode="ios"
              v-model="newFilters.sort"
              style="width: 100%"
            >
              <ion-segment-button value="retailer.name">
                <ion-label>{{ $t('filters.retailer') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="storeType.name">
                <ion-label>{{ $t('filters.storeType') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="store.name">
                <ion-label>{{ $t('filters.name') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="store.code">
                <ion-label>{{ $t('filters.code') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="store.city">
                <ion-label>{{ $t('filters.city') }}</ion-label>
              </ion-segment-button>
              <ion-segment-button value="store.grade">
                <ion-label>{{ $t('filters.grade') }}</ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
          <ion-list-header>
            <ion-label>{{ $t('labels.sortDirection') }}</ion-label>
          </ion-list-header>
          <ion-item lines="none">
            <ion-segment mode="ios" v-model="newFilters.direction">
              <ion-segment-button value="asc" layout="icon-start">
                <ion-icon :icon="icons.arrowUpOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.ascending') }}
                </ion-label>
              </ion-segment-button>
              <ion-segment-button value="desc" layout="icon-start">
                <ion-icon :icon="icons.arrowDownOutline"></ion-icon>
                <ion-label>
                  {{ $t('labels.descending') }}
                </ion-label>
              </ion-segment-button>
            </ion-segment>
          </ion-item>
        </ion-list>
        <div class="odn-modal-filters-buttons">
          <ion-button size="block" color="light" @click="reset">
            <ion-icon :icon="icons.refreshOutline" slot="start"></ion-icon>
            <ion-label>
              {{ $t('buttons.reset') }}
            </ion-label>
          </ion-button>
          <ion-button size="block" @click="dismissWithData">
            <ion-icon
              :icon="icons.checkmarkCircleOutline"
              slot="start"
            ></ion-icon>
            <ion-label>
              {{ $t('buttons.validate') }}
            </ion-label>
          </ion-button>
        </div>
      </ODNModalFilters>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonSegment,
  IonSegmentButton,
  IonListHeader,
  IonList,
  IonItem,
  IonChip,
  IonText,
  modalController,
} from '@ionic/vue';
import {
  close,
  closeCircle,
  search,
  trashOutline,
  arrowUpOutline,
  arrowDownOutline,
  refreshOutline,
  checkmarkCircleOutline,
} from 'ionicons/icons';

import ODNModalFilters from '@m/odn-modal-filters.vue';
import ODNModalRetailers from '@m/odn-modal-retailer-list.vue';

export default {
  name: 'ModalStoresFilters',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonSegment,
    IonSegmentButton,
    IonLabel,
    IonListHeader,
    IonList,
    IonItem,
    IonChip,
    IonText,
    ODNModalFilters,
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        close,
        search,
        trashOutline,
        closeCircle,
        arrowUpOutline,
        arrowDownOutline,
        refreshOutline,
        checkmarkCircleOutline,
      },
      newFilters: {
        retailers: [].concat(this.filters.retailers),
        sort: this.filters.sort,
        direction: this.filters.direction,
      },
    };
  },
  methods: {
    reset() {
      this.newFilters = {
        retailers: [],
        sort: 'store.name',
        direction: 'asc',
      };
      this.dismissWithData();
    },
    dismiss() {
      modalController.dismiss();
    },
    dismissWithData() {
      modalController.dismiss(this.newFilters);
    },
    async onRetailersModal() {
      const modal = await modalController.create({
        component: ODNModalRetailers,
        componentProps: {
          selected: this.newFilters.retailers,
        },
      });
      await modal.present();

      // wait for a response when closing the modal
      const newRetailer = (await modal.onDidDismiss()).data;

      if (newRetailer) {
        this.newFilters.retailers.push(newRetailer);
      }
    },
    onRemoveAllRetailers() {
      this.newFilters.retailers = [];
    },
    onRemoveRetailer(retailer) {
      this.newFilters.retailers = this.newFilters.retailers.filter(
        (r) => r.id !== retailer.id
      );
    },
  },
};
</script>
