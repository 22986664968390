<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="end">
          <ion-button @click="dismiss">
            <ion-icon slot="icon-only" :icon="icons.close"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>
          {{ title }}
        </ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
        ></ion-searchbar>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-list style="min-height: 100%">
        <div
          v-for="filteredGroupedItem in filteredGroupedItems"
          :key="filteredGroupedItem.id"
        >
          <ion-list-header>
            <ion-label>
              {{ filteredGroupedItem.name }}
            </ion-label>
          </ion-list-header>
          <ion-item
            v-for="item in filterItems(filteredGroupedItem.items)"
            :key="item.id"
            button
            @click="onSelect(item)"
            :disabled="item.id === selected"
            :detail="false"
          >
            <ion-icon :icon="icons.pricetagOutline" slot="start"></ion-icon>
            <ion-label>{{ format(item) }}</ion-label>
            <ion-ripple-effect></ion-ripple-effect>
          </ion-item>
        </div>
        <ion-item v-if="filteredGroupedItems.length === 0">
          <ion-label>{{ $t('labels.noContent') }}</ion-label>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonButton,
  IonButtons,
  IonIcon,
  IonLabel,
  IonList,
  IonListHeader,
  IonItem,
  IonSearchbar,
  IonRippleEffect,
  modalController,
} from '@ionic/vue';
import { close, search, pricetagOutline } from 'ionicons/icons';

export default {
  name: 'ModalGroupedSelect',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonButton,
    IonButtons,
    IonIcon,
    IonLabel,
    IonList,
    IonListHeader,
    IonItem,
    IonSearchbar,
    IonRippleEffect,
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    groupedItems: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      default: null,
    },
    formatting: {
      type: Function,
      default: null,
    },
  },
  data() {
    return {
      searchText: '',
      icons: {
        close,
        search,
        pricetagOutline,
      },
    };
  },
  computed: {
    filteredGroupedItems() {
      return this.groupedItems.filter(
        (group) =>
          group.items.filter((item) =>
            this.format(item)
              .toLowerCase()
              .includes(this.searchText.toLowerCase())
          ).length > 0
      );
    },
  },
  methods: {
    filterItems(items) {
      return items.filter((item) =>
        this.format(item)
          .toLowerCase()
          .includes(this.searchText.toLowerCase())
      );
    },
    dismiss() {
      modalController.dismiss();
    },
    onSelect(item) {
      modalController.dismiss(item);
    },
    format(item) {
      if (this.formatting) {
        return this.formatting(item);
      } else {
        return item.name;
      }
    },
  },
};
</script>
