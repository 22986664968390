<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>
          {{ $t('views.profile.title') }}
        </ion-title>
        <ion-buttons slot="end">
          <ion-button @click="onLanguagesModal">
            <ion-icon slot="icon-only" :icon="icons.earthOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <!-- Avatar -->
      <div class="odn-profile-avatar">
        <img v-if="profile.avatar" :src="profile.avatar" alt="Avatar" />
        <img
          v-else
          :src="`${s3Url}/medias/images/icons/icon-avatar.svg`"
          alt="Avatar"
          class="odn-profile-avatar-svg"
        />
      </div>
      <!-- Info -->
      <div class="odn-profile-header">
        <div v-if="!loading" class="odn-profile-header-meta">
          <div class="odn-profile-header-meta-name">
            {{ profile.firstname }} {{ profile.lastname }}
          </div>
          <div class="odn-profile-header-meta-email">
            {{ profile.email }}
          </div>
        </div>
        <ion-spinner v-else></ion-spinner>
        <div class="odn-profile-header-actions">
          <ion-button fill="clear" router-link="/tabs/profile/edit">
            <ion-icon :icon="icons.createOutline" slot="icon-only"></ion-icon>
          </ion-button>
        </div>
      </div>
      <!-- Actions -->
      <ion-list lines="none">
        <!-- <ion-item button router-link="/tabs/profile/options">
          <ion-icon :icon="icons.optionsOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.options') }}
          </ion-label>
        </ion-item> -->
        <ion-item button router-link="/tabs/profile/notifications">
          <ion-icon :icon="icons.notificationsOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.notifications') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="notificationCount > 0 ? 'danger' : null"
          >
            {{ notificationCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <ion-item button router-link="/tabs/profile/responses">
          <ion-icon :icon="icons.fileTrayFullOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.surveyResponses') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="profile.responseCount > 0 ? 'primary' : null"
          >
            {{ profile.responseCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <ion-item
          v-if="profile.role && profile.role.name !== 'deployment'"
          button
          router-link="/tabs/profile/tickets"
        >
          <ion-icon src="assets/icons/icon-tools.svg" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.tickets') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="profile.ticketCount > 0 ? 'primary' : null"
          >
            {{ profile.ticketCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <ion-item details button router-link="/tabs/appointments">
          <ion-icon :icon="icons.calendarOutline" slot="start"></ion-icon>
          <ion-label>
            {{ $t('buttons.appointments') }}
          </ion-label>
          <ion-note
            v-if="!loading"
            slot="end"
            :color="profile.appointmentCount > 0 ? 'primary' : null"
          >
            {{ profile.appointmentCount }}
          </ion-note>
          <ion-spinner v-else name="dots"></ion-spinner>
        </ion-item>
        <!-- <ion-item details button router-link="/tabs/profile/offline">
          <ion-icon
            :src="`${s3Url}/medias/images/icons/icon-no-wifi.svg`"
            slot="start"
          ></ion-icon>
          <ion-label>
            {{ $t('buttons.offlineMode') }}
          </ion-label>
        </ion-item> -->
        <ion-item details button router-link="/tabs/profile/device-permissions">
          <ion-icon
            :src="`${s3Url}/medias/images/icons/icon-phone-shield.svg`"
            slot="start"
          ></ion-icon>
          <ion-label>
            {{ $t('buttons.devicePermissions') }}
          </ion-label>
        </ion-item>
        <ion-item details button router-link="/tabs/profile/informations">
          <ion-icon
            :icon="icons.informationCircleOutline"
            slot="start"
          ></ion-icon>
          <ion-label>
            {{ $t('buttons.informations') }}
          </ion-label>
        </ion-item>
      </ion-list>
      <div class="ion-padding">
        <ion-button
          @click="onLogout"
          color="danger"
          size="block"
          fill="outline"
        >
          <ion-icon :icon="icons.logOutOutline" slot="start"></ion-icon>
          {{ $t('buttons.logout') }}
        </ion-button>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import {
  earthOutline,
  createOutline,
  notificationsOutline,
  fileTrayFullOutline,
  calendarOutline,
  helpBuoyOutline,
  logOutOutline,
  optionsOutline,
  informationCircleOutline,
} from 'ionicons/icons';

import ODNModalLanguages from '@m/odn-modal-languages.vue';

import APIService from '@s/api.service';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
  IonButtons,
  IonSpinner,
  IonNote,
  IonRefresher,
  IonRefresherContent,
  modalController,
  toastController,
} from '@ionic/vue';

export default {
  name: 'Profile',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonList,
    IonItem,
    IonLabel,
    IonButton,
    IonButtons,
    IonSpinner,
    IonNote,
    IonRefresher,
    IonRefresherContent,
  },
  data: () => ({
    profile: {},
    loading: true,
    icons: {
      earthOutline,
      createOutline,
      notificationsOutline,
      fileTrayFullOutline,
      calendarOutline,
      helpBuoyOutline,
      logOutOutline,
      optionsOutline,
      informationCircleOutline,
    },
  }),
  computed: {
    ...mapState('notifications', ['notificationCount']),
    s3Url() {
      return process.env.VUE_APP_S3_URL;
    },
  },
  ionViewDidEnter() {
    this.fetchData();
  },
  ionViewDidLeave() {
    this.loading = true;
  },
  methods: {
    ...mapActions('auth', {
      logout: 'logout',
      saveProfile: 'saveProfile',
    }),
    async fetchData() {
      this.loading = true;
      try {
        const response = await APIService.get('/auth/profile');
        this.profile = response.data.user;
        this.saveProfile(this.profile);
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.profile.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async onRefresh(event) {
      await this.fetchData(true);
      event.target.complete();
    },
    async onLanguagesModal() {
      const modal = await modalController.create({
        component: ODNModalLanguages,
      });
      return modal.present();
    },
    async onLogout() {
      await this.logout();
      this.$router.replace('/auth/login');
    },
  },
};
</script>

<style lang="scss">
.odn-profile {
  &-avatar {
    display: flex;
    width: 100%;
    height: 140px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // background-color: rgba(#2875c2, 0.2);

    @media (max-height: 568px) {
      height: 100px;
    }

    > img {
      display: block;
      width: 96px;
      height: 96px;
      border-radius: 50%;
      background-color: rgba(#fff, 0.6);

      @media (max-height: 568px) {
        width: 64px;
        height: 64px;
      }

      @media (prefers-color-scheme: dark) {
        background-color: rgba(#000, 0.6);
      }
    }
  }

  &-header {
    display: flex;
    padding: 16px 16px 24px 32px;
    flex-direction: row;
    //flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    &-meta {
      &-name {
        font-weight: 600;
      }
      &-email {
        font-size: 0.9em;
      }
    }
  }
}
</style>
