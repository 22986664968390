// Vue dependences
import { createApp } from 'vue';
import { createI18n } from 'vue-i18n';

// i18n locales
import locales from './locales.js';

// App
import App from './App.vue';

// Store
import store from './store';

// Router
import router from './router';

// Ionic
import { IonicVue } from '@ionic/vue';

// PWA Elements
import { defineCustomElements } from '@ionic/pwa-elements/loader';

// VCalendar
import { setupCalendar } from 'v-calendar';

// Dayjs
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';

import * as localizedFormat from 'dayjs/plugin/localizedFormat';
import * as duration from 'dayjs/plugin/duration';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
import * as objectSupport from 'dayjs/plugin/objectSupport';

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

const i18n = createI18n({
  locale: 'fr',
  fallbackLocale: 'fr',
  messages: locales,
});

const app = createApp(App)
  .use(IonicVue)
  .use(i18n)
  .use(setupCalendar)
  .use(store)
  .use(router);

defineCustomElements(window);

app.config.globalProperties.$dayjs = dayjs;

router.isReady().then(() => {
  app.mount('#app');
});
