<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button
          tab="appointmentList"
          layout="icon-start"
          href="/tabs/appointments/list"
        >
          <ion-icon :icon="icons.listOutline"></ion-icon>
          <ion-label>{{ $t('buttons.list') }}</ion-label>
        </ion-tab-button>

        <ion-tab-button
          tab="appointmentCalendar"
          layout="icon-start"
          href="/tabs/appointments/calendar"
        >
          <ion-icon :icon="icons.calendarClearOutline"></ion-icon>
          <ion-label>{{ $t('buttons.calendar') }}</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonRouterOutlet,
  IonIcon,
  IonLabel,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/vue';
import { listOutline, calendarClearOutline } from 'ionicons/icons';

export default {
  name: 'Appointments',
  components: {
    IonRouterOutlet,
    IonIcon,
    IonLabel,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
  },
  data() {
    return {
      icons: {
        listOutline,
        calendarClearOutline,
      },
    };
  },
};
</script>
