<template>
  <div class="odn-no-content">
    <div class="odn-no-content-icon">
      <ion-icon :icon="ban" />
    </div>
    <div class="odn-no-content-label">
      {{ $t('labels.noContent') }}
    </div>
  </div>
</template>

<script>
import { IonIcon } from '@ionic/vue';
import { ban } from 'ionicons/icons';

export default {
  components: {
    IonIcon,
  },
  data() {
    return {
      ban,
    };
  },
};
</script>

<style lang="scss">
.odn-no-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;

  color: var(--ion-color-medium);

  &-icon {
    font-size: 2rem;
  }
}
</style>
