<template>
  <ion-page>
    <ion-tabs>
      <ion-router-outlet></ion-router-outlet>
      <ion-tab-bar slot="bottom">
        <ion-tab-button tab="offlineStores" href="/offline/stores">
          <ion-icon :icon="icons.storefrontOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="offlineAppointments" href="/offline/appointments">
          <ion-icon :icon="icons.calendarOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="offlineProcedures" href="/offline/procedures">
          <ion-icon :icon="icons.helpBuoyOutline"></ion-icon>
        </ion-tab-button>

        <ion-tab-button tab="online" href="/tabs/profile/offline">
          <ion-icon :icon="icons.exitOutline"></ion-icon>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonRouterOutlet,
  IonIcon,
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/vue';
import {
  storefrontOutline,
  calendarOutline,
  helpBuoyOutline,
  exitOutline,
} from 'ionicons/icons';

export default {
  name: 'Offline',
  components: {
    IonRouterOutlet,
    IonIcon,
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
  },
  data() {
    return {
      icons: {
        storefrontOutline,
        calendarOutline,
        helpBuoyOutline,
        exitOutline,
      },
    };
  },
};
</script>
