<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('views.stores.title') }}</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="store in stores"
        :key="store.id"
        @click="onSelect(store)"
        button
      >
        <ion-card-header>
          <ion-card-subtitle>
            {{ store.retailer.name }} - {{ store.storeType.name }}
          </ion-card-subtitle>
          <ion-card-title>{{ store.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <ion-row>
            <ion-col>
              <p>{{ store.address }}</p>
              <p>{{ store.postcode }} {{ store.city }}</p>
              <p>{{ store.country }}</p>
              <br />
              <p v-if="store.region">
                {{ $t('labels.region') }}
                {{ store.region.name }}
              </p>
              <p v-if="store.territory">
                {{ $t('labels.territory') }}
                {{ store.territory.name }}
              </p>
              <p v-if="store.zone">
                {{ $t('labels.zone') }}
                {{ store.zone.name }}
              </p>
            </ion-col>
            <ion-col class="ion-text-end">
              <p v-if="store.grade">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade') }} {{ store.grade }}
              </p>
              <p v-if="store.grade2">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade2') }} {{ store.grade2 }}
              </p>
              <p v-if="store.grade3">
                <ion-icon :icon="icons.star"></ion-icon>
                {{ $t('labels.grade3') }} {{ store.grade3 }}
              </p>
              <p v-if="store.code">
                {{ store.retailer.storeCode || this.$t('labels.code') }}
                {{ store.code }}
              </p>
              <p v-if="store.code2">
                {{ this.$t('labels.code2') }}
                {{ store.code2 }}
              </p>
              <p v-if="store.code3">
                {{ this.$t('labels.code3') }}
                {{ store.code3 }}
              </p>
            </ion-col>
          </ion-row>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="stores.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="parseMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonIcon,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardTitle,
  IonCardHeader,
  IonRow,
  IonCol,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  toastController,
} from '@ionic/vue';
import { star } from 'ionicons/icons';

import ODNNoContent from '@c/odn-no-content.vue';

export default {
  name: 'OfflineStores',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon,
    IonCard,
    IonCardContent,
    IonCardSubtitle,
    IonCardTitle,
    IonCardHeader,
    IonRow,
    IonCol,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    ODNNoContent,
  },
  data() {
    return {
      stores: [],
      storeList: [],
      page: 0,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      icons: {
        star,
      },
      loading: true,
    };
  },
  computed: {
    ...mapGetters('offline', ['getSection']),
  },
  async ionViewDidEnter() {
    await this.loadLocalData();
    await this.parseData(true);
  },
  ionViewDidLeave() {
    this.stores = [];
    this.storeList = [];
    this.page = 0;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('stores', ['selectStore']),
    async loadLocalData() {
      this.loading = true;
      try {
        const rawData = await Filesystem.readFile({
          path: 'stores.json',
          directory: Directory.Data,
        });

        this.storeList = JSON.parse(Base64.decode(rawData.data));
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.stores.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async parseData(clear = false) {
      if (clear) {
        this.page = 0;
        this.stores = [];
      }

      this.stores = this.stores.concat(
        this.storeList
          .filter((s) => {
            const search = this.searchText.toLowerCase();
            return (
              s.name.toLowerCase().indexOf(search) > -1 ||
              s.retailer.name.toLowerCase().indexOf(search) > -1 ||
              (s.code && s.code.toLowerCase().indexOf(search) > -1) ||
              (s.city && s.city.toLowerCase().indexOf(search) > -1)
            );
          })
          .slice(this.page * this.limit, this.page * this.limit + this.limit)
      );

      if (this.stores.length < this.storeList.length) {
        this.disableInfiniteScrolling = false;
      } else {
        this.disableInfiniteScrolling = true;
      }
    },
    async parseMoreData(event) {
      this.page++;
      await this.parseData();
      event.target.complete();
    },
    async onSearch() {
      await this.parseData(true);
    },
    async onRefresh(event) {
      await this.parseData(true);
      event.target.complete();
    },
    onSelect(store) {
      this.selectStore(store);
      this.$router.push(`/offline/stores/${store.id}`);
    },
  },
};
</script>
