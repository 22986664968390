const state = () => ({
  selectedProcedure: null,
});

const mutations = {
  SELECT_PROCEDURE(state, payload) {
    state.selectedProcedure = payload;
  },
  DESELECT_PROCEDURE(state) {
    state.selectedProcedure = null;
  },
};

const actions = {
  selectProcedure({ commit }, payload) {
    commit('SELECT_PROCEDURE', payload);
  },
  deselectProcedure({ commit }) {
    commit('DESELECT_PROCEDURE');
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
