import axios from 'axios';
import router from '../router';
import store from '../store';

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch('auth/logout');
      router.push('/auth/login');
    }
    return Promise.reject(error);
  }
);

class APIService {
  static get(endPoint, params = {}) {
    return axios.get(`${process.env.VUE_APP_API_URL}${endPoint}`, {
      params,
      headers: {
        Authorization: 'Bearer ' + store.state.auth.authData.token,
      },
    });
  }

  static post(endPoint, body, params = {}) {
    return axios.post(`${process.env.VUE_APP_API_URL}${endPoint}`, body, {
      params,
      headers: {
        Authorization: 'Bearer ' + store.state.auth.authData.token,
      },
    });
  }

  static upload(endPoint, data, params = {}, method = 'post') {
    return axios({
      method: method,
      url: `${process.env.VUE_APP_API_URL}${endPoint}`,
      data,
      params,
      headers: {
        Authorization: 'Bearer ' + store.state.auth.authData.token,
        'content-type': 'multipart/form-data',
      },
    });
  }

  static put(endPoint, body, params = {}) {
    return axios.put(`${process.env.VUE_APP_API_URL}${endPoint}`, body, {
      params,
      headers: {
        Authorization: 'Bearer ' + store.state.auth.authData.token,
      },
    });
  }
}

export default APIService;
