<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('views.help.title') }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content fullscreen>
      <div class="ion-text-center ion-padding ion-margin-bottom">
        <div style="font-size: 64px">
          <ion-icon :icon="helpBuoyOutline" slot="start"></ion-icon>
        </div>
        {{ $t('views.help.description') }}
      </div>
      <ion-item lines="none" button detail router-link="/tabs/help/procedures">
        <ion-icon :icon="clipboardOutline" slot="start"></ion-icon>
        <ion-label>
          {{ $t('menu.procedures') }}
        </ion-label>
      </ion-item>
      <ion-item
        lines="none"
        button
        detail
        router-link="/tabs/help/emergency-numbers"
      >
        <ion-icon :icon="callOutline" slot="start"></ion-icon>
        <ion-label>
          {{ $t('menu.emergencyNumbers') }}
        </ion-label>
      </ion-item>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonContent,
  IonItem,
  IonIcon,
  IonLabel,
} from '@ionic/vue';
import { clipboardOutline, callOutline, helpBuoyOutline } from 'ionicons/icons';

export default {
  name: 'Help',
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonItem,
    IonIcon,
    IonLabel,
  },
  data() {
    return {
      clipboardOutline,
      callOutline,
      helpBuoyOutline,
    };
  },
};
</script>
