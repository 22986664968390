const i18nTranslations = process.env.VUE_APP_I18N_TRANSLATIONS || 'default';

import dflt from './default/pl.json'; // Default translations
import { merge } from '../shared/helper';

let overrides = {};

if (i18nTranslations === 'omni') {
  overrides = require('./omni/pl.json'); // OMNI specific translations
}

export default merge(dflt, overrides);
